<template>
  <div>
    <div id="mobileScrollTarget"></div>
    <div id="app" v-cloak>
      <div v-if="navigationState == true">
        <AutoLogout />
      </div>       
      <component :is="layoutComponent" />
    </div>
  </div>
</template>

<script>
import AutoLogout from "@/components/AutoLogout";
//import MainNavbar from "@/components/MainNavbar";
import DashboardLayout from "@/components/layouts/DashboardLayout";
import WebLayout from './components/layouts/WebLayout.vue';

export default {
  name: "App",
  components: {
    // MainNavbar,
    //MainNavbarDEV,
    AutoLogout,
    WebLayout,
    DashboardLayout,
  },
  data() {
    return {
      prevHeight: 0,
    };
  },
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    SSCstoreLink() {
      let url = 'https://portal.mypropago.com/Security/Spacesaver?companyGuid=ae007963-e6dc-47cc-92c5-898fc8add77a&email=' + this.email + '&username=' + this.userName + '&fName=' + this.firstName + '&lName=' + this.lastName
      window.open(url , '_blank');
    },
  },
  computed: {

    layoutComponent() {
      if (this.$store.state.isDashboard && this.$store.state.nav) {
        return DashboardLayout
      } else {
        return WebLayout;
      }     
    },
    getyear() {
      return new Date().getFullYear();
    },
    cart() {
      return this.$store.state.cart;
    },
    images() {
      return this.$store.state.images;
    },
    videos() {
      return this.$store.state.videos;
    },
    pdfs() {
      return this.$store.state.pdfs;
    },
    navigationState() {
      return this.$store.state.nav;
    },
    firstName() {
      return this.$store.state.user.FirstName;
    },
    lastName() {
      return this.$store.state.user.LastName;
    },
    userName(){
      return this.$store.state.user.UserName
    },
    email() {
      return this.$store.state.user.Email;
    },
    activity() {
      return this.$store.state.idleVue.isIdle;
    },
  },

  mounted() {
    if (this.$store.state.isLoggedIn) {
      this.$store.commit("SET_EO_COUNT");
      this.$store.commit("SET_NEW_POST_COUNT");
    }
  },
  watch: {
  },
};
</script>

<style lang="scss">
[v-cloak] {
  display: none;
}
@import "@/assets/scss/main.scss";
.pages i {
  color: #b5b5b5;
  margin-right: 5px;
}
.pages button {
  color: #b5b5b5;
  list-style-type: none;
  padding: 0px;
  -webkit-appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 300 !important;
  transition: color 0.5s ease;
}
.pages button:hover {
  text-decoration: none;
  color: #fff;
}
.pages button:active {
  text-decoration: none;
  color: #fff;
}
.pages button:hover i {
  text-decoration: none !important;
}
.pages button:active i {
  text-decoration: none !important;
}
.pages span {
  color: #b5b5b5;
  margin-right: 5px;
}
.pages a {
  transition: color 0.5s ease;
}
.pages a:hover {
  color: #fff;
  text-decoration: none;
}
.pages a:active {
  color: #fff;
  text-decoration: none;
}
.pages a:hover {
  color: #fff;
  text-decoration: none;
}
.pages a:active {
  color: #fff;
  text-decoration: none;
}
.table-striped a:visited {
  color: #4d5967 !important;
}
footer h3 {
  color: #b5b5b5;
}
.terms a {
  color: #b5b5b5;
  transition: color 0.5s ease;
}
.terms a:hover {
  color: #fff;
  text-decoration: none;
}
.terms a:active {
  color: #b5b5b5;
  text-decoration: none;
}
.ls-none {
  list-style-type: none;
  padding-left: 0;
}
.ls-none li {
  display: inline-block;
  margin-right: 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  padding-right: 20px;
  line-height: 1;
}
.ls-none li:last-child {
  border-right: none;
}
@media all and (max-width: 767px) {
  .ls-none li:nth-child(2) {
    border-right: none;
  }
}

@media all and (max-width: 552px) {
  .ls-none li {
    display: block;
    margin-right: 0px;
    border-right: none;
    margin-left: 0;
    padding-right: 0px;
    padding-left: 0;
    margin-bottom: 10px;
  }
}
/*
.ls-none li span {
    border-radius: 50%;
    border: 1px dashed black;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
    background-color: #fff;
    display: inline-block;
    position: relative;
    text-align: center;
}

.ls-none li:before {
    content: '';
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    left: 15px;
    background: #000;
    z-index: -1;
}

.ls-none li:last-child:before {
    display: none;
}
*/

@media print {
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
    padding: 0;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
    display: none;
  }
  .noprint {
    visibility: hidden;
  }
}

.dashboard-container{
  padding: 0 40px;
}

.ck-content p {
  margin: 0 0!important;
  padding: 0 0!important;
}

.cke_editable p {
    margin: 0!important;
    padding: 0!important;
}

</style>
